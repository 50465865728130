import React, { useEffect } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
} from 'react-native';
import firebase from "firebase/app";
import "firebase/auth";

import styles from './style';
import { Assets } from '../../constants/Images';
// import { auth } from '../../constants/Config';

const suffix = "americanradioclub.com"
const suffix2 = "hamradioprep.com"

const Login = ({ navigation }) => {

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const { email } = user;
        if (IsExist(email)) {
          navigation.navigate("Admin");
        }
      }
    })
  }, []);

  const IsExist = (e) => {
    const temp = e.toLowerCase();
    return temp.includes(suffix) || temp.includes(suffix2);
  }

  const GGLogin = async () => {
    const googleProvider = await new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(googleProvider).then((res) => {
      if (res) {
        if (IsExist(res.user.email)) {
          navigation.navigate("Admin");
        }
      }
    }).catch((error) => {
      console.log("-------", error.message);
      // GGLogin();
    });
  }

  // const logOut =() => {
  //   firebase.auth().signOut().then(() => {
  //     console.log("logged out");
  //   }).catch((error) => {
  //     console.log(error.message);
  //   })
  // }

  return (
    <View style={styles.container}>
      <View style={styles.marktitle}>
        <Image source={Assets.titlelogo} style={styles.waveImg} resizeMode="contain" />
      </View>
      <View style={styles.markCon}>
        <Image source={Assets.loginmark} style={styles.mark} />
      </View>
      <View style={styles.socialCon}>
        <TouchableOpacity onPress={GGLogin} style={styles.ggBtn}>
          <Image source={Assets.google} style={styles.facebook} resizeMode="contain" />
          <Text style={styles.ggTxt}>SIGN IN WITH GOOGLE</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
};

export default Login;
